<template>
  <v-row>
    <v-col cols="8">
      <v-dialog 
        v-model="dialog" 
        persistent 
        max-width="600px"
      >
        <template
          #activator="{ on, attrs }"
        >
          <v-btn 
            color="secondary" 
            dark 
            v-bind="attrs" 
            outlined
            v-on="on" 
          >
            Contate o suporte
          </v-btn>
        </template>
        <v-card>
          <v-card-title style="background-color: #002166;">
            <v-toolbar-title class="py-0">
              <div style="border-bottom: 5px solid white; width: 210px;" />
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              color="secondary"
              elevation="0"
              small
              class="pa-0" 
              @click="clearAndClose"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-container class="pl-5">
              <form style="width: 95%;">
                <h3 
                  class="pt-5"
                >
                  Nome:
                </h3>
                <v-text-field
                  v-model="name"
                  filled
                  class="pt-0"
                  :error-messages="nameErrors"
                  :counter="60"
                  maxlength="60"
                  required
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                />
                <h3
                  class="pt-0"
                >
                  Email:
                </h3>
                <v-text-field
                  v-model="email"
                  class="pt-0"
                  filled
                  :error-messages="emailErrors"
                  :counter="60"
                  maxlength="60"
                  required
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                />
                <h3
                  class="pt-0"
                >
                  Canal:
                </h3>
                <v-select
                  v-model="select"
                  filled
                  class="pb-0"
                  :items="items"
                  :error-messages="selectErrors"
                  label="Selecione Um Canal / Serviço"
                  required
                  @change="$v.select.$touch()"
                  @blur="$v.select.$touch()"
                />
              </form>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-3">
            <v-spacer />
            <v-btn
              class="mr-8"
              elevation="0"
              outlined
              color="secondary"
              @click="submit"
            >
              Iniciar Chat
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    name: { required, maxLength: maxLength(60) },
    email: { required, email },
    select: { required },
    checkbox: {
      checked(val) {
        return val;
      }
    }
  },

  data: () => ({
    dialog: false,
    urlPabxDigitro: "https://pabx.cassems.com.br/chatclient/chat.php?",
    parNotif1: "1",
    parNotif2: "1",
    name: "",
    email: "",
    select: null,
    items: ["Suporte Hospitais", "Suporte Operadora"]
  }),

  computed: {
    selectErrors() {
      const errors = [];
      if (!this.$v.select.$dirty) return errors;
      !this.$v.select.required &&
        errors.push("É necessário selecionar um Canal / Serviço");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push(
          "RECOMENDAÇÃO! O seu nome deve ter no máximo 60 Caracteres!"
        );
      !this.$v.name.required && errors.push("É necessário digitar um nome!");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email &&
        errors.push("O endereço de Email digitado não é válido!");
      !this.$v.email.required && errors.push("É necessário digitar um Email!");
      return errors;
    }
  },

  methods: {
    submit() {
      this.$v.$touch();
      window.open(
        this.urlPabxDigitro +
          "notif=" +
          this.parNotif1 +
          "&" +
          "beep=" +
          this.parNotif2 +
          "&" +
          "ci=" +
          this.name +
          "&" +
          "ea=" +
          this.email +
          "&" +
          "servico=" +
          this.select,
        "_blank",
        "location=yes,width=405,height=486,status=no,toolbar=no,menubar=no,scrollbars=no,resizable=no,titlebar=no,directories=no"
      );
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
    },
    clearAndClose() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
      this.dialog = false;
    }
  }
};
</script>
